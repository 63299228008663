@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    /**** Home page ****/
        /*** COMPONENT CountryInfo and children. ***/

            /* Styles applied in a screen width of less than 768px. */
                .country-checkbox {
                    @apply hidden [&:checked~div]:grid-rows-[1fr] [&:checked+h2_svg]:rotate-180
                }
                .countryinfo-know-us {
                    @apply grid gap-[.8rem] [&>h3]:title-color
                }
                .countryinfo-know-us-sub-ul {
                    @apply [&>li_svg]:[font-size:1.4rem] flex flex-wrap gap-[.5rem] 
                }
                .countryinfo-contact-us {
                    @apply grid gap-[.8rem] [&>h3]:text-color
                }
                .countryinfo-section {
                    @apply grid w-full bg-white overflow-hidden rounded-xl will-change-transform 
                }
                .wrapper {
                    @apply grid grid-rows-[0fr] will-change-[grid-template-rows] [transition:grid-template-rows_0.3s_ease-out]
                }
                .inner {
                    @apply grid-rows-[1fr] will-change-[grid-template-rows] overflow-hidden grid gap-[1rem] [&>a]:mt-[1rem] [&>a]:w-fit
                }
                .countryinfo-url {
                    @apply px-[1.2rem] font-bold title-color [font-size:1.1rem] 
                }
                .countryinfo-label-span {
                    @apply py-[.35rem] px-[1.2rem] text-[1.2rem] cursor-pointer flex justify-between items-center [&_svg]:transition-transform [&>span]:grid [&>span]:place-items-center [text-shadow:0px_0px_2px_black]
                }
                .countryinfo-contact-us-sub-ul {
                    @apply grid [&>li_svg]:text-sm [&>li]:flex [&>li]:justify-start gap-[.5rem] [&>li]:gap-[.5rem] [&>li]:items-center
                }
                .countryinfo-ul {
                    @apply flex flex-wrap justify-between gap-[1.2rem] [&>li_h3]:[font-size:.95rem] [&>li_ul_a]:[font-size:.7rem] [&>li]:flex [&>li]:flex-col
                }
                .countryinfo-div {
                    @apply p-[1.2rem] pt-[0] pb-[1.3rem]
                }
            /* END styles applied in a screen width of less than 768px. */

            /* Styles applied at 768px screen width and wider. */
                .countryinfo-know-us-md {
                    @apply md:gap-[1rem]
                }
                .countryinfo-know-us-sub-ul-md {
                    @apply md:[&>li_svg]:[font-size:2.5rem] md:gap-[.7rem] 
                }
                .countryinfo-contact-us-md {
                    @apply md:gap-[1rem]
                }
                .inner-md {
                    @apply md:[&>a]:mt-[1.5rem] md:[&>a]:pb-[.5rem]
                }
                .countryinfo-url-md {
                    @apply md:px-[2rem] md:[font-size:1.3rem] 
                }
                .countryinfo-label-span-md {
                    @apply md:py-[1rem] md:px-[2rem] md:text-[1.25rem]
                }
                .countryinfo-contact-us-sub-ul-md {
                    @apply md:[&>li_svg]:text-xl md:gap-[.8rem]
                }
                .countryinfo-ul-md {
                    @apply md:gap-[3rem] md:[&>li_h3]:[font-size:1.2rem] md:[&>li_ul_a]:[font-size:1rem] 
                }
                .countryinfo-div-md {
                    @apply md:pl-[2rem] md:pr-[2rem]
                }
            /* END styles applied at 768px screen width and wider. */

            /* Styles applied at 1280px screen width and wider. */
                .country-checkbox-xl {
                    @apply xl:[&+h2_label_>span_span:last-of-type]:hidden xl:[&~div]:grid-rows-[1fr]
                }
                .countryinfo-know-us-xl {
                    @apply xl:gap-[1.5vh] xl:text-[1.3vw]
                }
                .countryinfo-know-us-sub-ul-xl {
                    @apply xl:[&>li_svg]:[font-size:2vw] xl:gap-x-[.5vw] 
                }
                .countryinfo-contact-us-xl {
                    @apply xl:gap-[1.8vh] xl:text-[1.3vw]
                }
                .countryinfo-section-xl {
                    @apply xl:rounded-none xl:pb-[7vh] xl:rounded-se-[1.5vw] xl:absolute xl:left-0 xl:bottom-0 xl:pointer-events-none xl:[&>div]:gap-y-[3vh] xl:[&>div]:grid xl:translate-y-full xl:[transition:transform_300ms_ease] xl:will-change-transform
                }
                .wrapper-xl {
                    @apply xl:[transition:grid-template-rows_0s_ease-out]
                }
                .inner-xl {
                    @apply xl:[&>a]:mt-[3.5vh] xl:[&>a]:p-0 xl:[&>a]:ml-[3.5vw] xl:gap-[3vh]
                }
                .countryinfo-url-xl {
                    @apply xl:[font-size:1.9vw] xl:px-[3.5vw]
                }
                .countryinfo-label-span-xl {
                    @apply xl:px-[3.5vw] xl:cursor-auto xl:py-[1.3vh] xl:[font-size:1.8vw] 
                }
                .countryinfo-contact-us-sub-ul-xl {
                    @apply xl:[&>li_svg]:text-[1.5vw] 2xl:[&>li_svg]:text-[1.2vw] xl:[&>li]:gap-x-[.6vw] xl:gap-y-[1vh] [&>li_a]:text-color
                }
                .countryinfo-ul-xl {
                    @apply xl:[&>li_ul_a]:[font-size:1vw] xl:[&>li_h3]:[font-size:1.1vw] xl:gap-[1vw]
                }
                .countryinfo-div-xl {
                    @apply xl:pl-[3.5vw] xl:pr-[6vw] xl:pb-[4vh]
                }
            /* END styles applied at 1280px screen width and wider. */

        /*** END COMPONENT CountryInfo. ***/

        /*** COMPONENT EuropeMap. ***/
            .country {
                @apply cursor-pointer will-change-[fill] [transition:_fill_200ms_ease-in]
            }
        /*** END COMPONENT EUROPE MAP. ***/ 

        /*** COMPONENT Home ***/

            /* Styles applied in a screen width of less than 768px. */
                .home-img {
                    @apply block w-[100%] h-auto cursor-pointer
                }
                .home-main {
                    @apply rounded-xl grid gap-1
                }
                .home-parent-container {
                    @apply flex flex-col overflow-y-hidden p-[calc(.2rem+2%)] pt-[2rem]
                }
                .home-left-container {
                    @apply flex flex-col gap-[2rem] justify-between w-full
                }
                .home-section-container {
                    @apply flex flex-col text-center gap-[1vw]
                }
                .home-section {
                    @apply flex w-full justify-center
                } 
                .home-section-p-container {
                    @apply hidden
                }
                .home-header {
                    @apply flex justify-center items-center flex-col gap-[4vw] gap-y-[9vh] 
                }
                .home-logo {
                    @apply pl-0 pt-0 mr-0 grid place-items-center h-[55px]
                }
                .home-main-title-static {
                    @apply text-2xl font-bold w-fit [line-height:1] title-color
                }
                .home-main-title-dynamic {
                    @apply text-2xl font-bold w-fit [line-height:1] title-color hidden
                }
                .home-p {
                    @apply [font-size:1.6vw] text-color w-fit 
                }
                .home-right-container {
                    @apply h-screen w-[45%] hidden justify-end
                }
                .home-footer {
                    @apply mobile-and-tablet-padding w-full pb-28 footer-bg footer-text-color mt-6
                }
                .home-footer-div {
                    @apply mx-auto max-w-4xl
                }
                .home-footer-div-div {
                    @apply mx-2 flex flex-wrap justify-between gap-y-10 gap-x-20
                }
                .home-footer-ul-first {
                    @apply grid gap-3 text-xs
                }
                .home-footer-ul {
                    @apply grid gap-3 text-sm h-fit
                }
                .home-footer-address {
                    @apply flex flex-col
                }
            /* END styles applied in a screen width of less than 1280px. */

            /* Styles applied at 768px screen width and wider. */
                .home-left-container-md {
                    @apply md:gap-[4rem]
                }
                .home-img-md {
                    @apply md:w-[400px]
                }
                .home-main-title-static-md {
                    @apply md:text-[2.25rem]
                }
                .home-main-title-dynamic-md {
                    @apply md:text-[2.25rem]
                }
                .home-main-md {
                    @apply md:gap-4
                }
                .home-footer-md {
                    @apply md:pb-10
                }
            /* End styles applied at 768px screen width and wider. */

            /* Styles applied at 1280px screen width and wider. */
                .home-img-xl {
                    @apply xl:w-[25vw]
                }
                .home-main-xl {
                    @apply xl:relative xl:rounded-none xl:overflow-visible xl:gap-0
                }
                .home-parent-container-xl {
                    @apply xl:flex-row xl:p-0 xl:pt-0 xl:h-screen xl:justify-between xl:gap-[1vw]
                }
                .home-left-container-xl {
                    @apply xl:[width:clamp(48vw,100%,48vw)] xl:h-full
                }
                .home-section-container-xl {
                    @apply xl:justify-end xl:items-end text-center xl:w-fit xl:translate-y-[-4vh]
                }
                .home-section-xl {
                    @apply xl:will-change-transform xl:justify-end xl:[transition:transform_300ms_ease]
                }
                .home-header-xl {
                    @apply xl:justify-between xl:items-start
                }
                .home-section-p-container-xl {
                    @apply xl:grid place-items-center w-full
                }
                .home-logo-xl {
                    @apply xl:pl-[3.5vw] xl:pt-[4vh] xl:mr-[3vw] h-auto
                }
                .home-main-title-static-xl {
                    @apply xl:[font-size:3.2vw] xl:[transition:font-size_200ms_ease-in-out] xl:whitespace-nowrap xl:hidden
                }
                .home-main-title-dynamic-xl {
                    @apply xl:[font-size:3.2vw] xl:[transition:font-size_200ms_ease-in-out] xl:whitespace-nowrap xl:block
                }
                .home-p-xl {
                    @apply xl:[font-size:2.2vw] xl:[transition:font-size_200ms_ease-in-out,opacity_200ms_ease]
                }
                .home-right-container-xl {
                    @apply xl:flex xl:w-[50%]
                }
                .home-footer-xl {
                    @apply xl:absolute xl:bottom-0 xl:left-0 xl:z-10 xl:text-[.75vw] xl:footer-text-color xl:footer-bg xl:p-[.5vw] xl:gap-x-[2vw] xl:px-[3.5vw] xl:mt-0 xl:w-screen
                }
                .home-footer-div-xl {
                    @apply xl:max-w-none xl:mx-0 xl:pb-0
                }
                .home-footer-div-div-xl {
                    @apply xl:justify-start xl:mx-0 xl:gap-y-0 xl:gap-x-[3vw]
                }
                .home-footer-ul-xl {
                    @apply xl:flex xl:text-[.75vw] xl:justify-center xl:items-center xl:gap-[2vw]
                }
                .home-footer-address-xl {
                    @apply xl:flex-row xl:gap-x-[1vw]
                }
            /* END styles applied at 1280px screen width and wider. */
        
        /*** END COMPONENT Home ***/
    /**** END Home page ****/

    /**** Cookie policy page and Legal note page ****/
        .cp-lp-container {
            @apply mobile-and-tablet-padding grid place-items-center gap-10 max-w-7xl m-auto
        }
        .cp-lp-header {
            @apply grid place-items-center
        }
        .cp-lp-main {
            @apply grid gap-10
        }
        .cp-lp-section {
            @apply grid gap-7 mt-4 mx-2 text-color
        }
        .cp-lp-h1 {
            @apply text-2xl md:text-4xl text-center font-semibold title-color
        }
        .cp-lp-h2 {
            @apply text-xl font-semibold title-color
        }
        .cp-lp-footer {
            @apply w-full text-color footer-text-color pb-28 md:pb-2
        }
        .cp-lp-footer-div {
            @apply max-w-7xl mx-auto mobile-and-tablet-padding
        }
        .cp-lp-footer-div-div {
            @apply flex flex-wrap gap-y-10 gap-x-20 justify-between mx-2
        }
        .cp-lp-footer-ul-first {
            @apply text-xs grid gap-3 h-fit [&>li]:h-fit [&>li]:w-fit
        }
        .cp-lp-footer-ul {
            @apply text-sm grid gap-3 h-fit [&>li]:h-fit [&>li]:w-fit
        }
    /**** END Cookie policy page ****/
    
    /**** General styles ****/
        .mobile-and-tablet-padding {
            @apply p-[calc(.2rem+2%)] pt-[2rem]
        }
        .title-color {
             @apply text-[#5a5a59]
        }
        .text-color {
            @apply text-[#706f6f]
        }
        .footer-text-color {
            @apply text-[#414141]
        }
        .footer-bg {
            @apply bg-[#ededed]
        }
    /**** END General styles ****/
}

* { -webkit-tap-highlight-color: rgba(0,0,0,0); }

:root {
    --dark-green: #689170;
    --light-green: #A6BDAA;
    --yellow-green: #CDD47A;
    --blue: #7290AC;
    --purple: #635C84;
}

body {
    background-color: #F7F7F7;
    font-family: 'Harmonia Sans Pro Cyr';
}

.selected-country-info {
    pointer-events: initial !important;
    visibility: initial !important;
    transform: translateY(0%) !important;
    z-index: 1;
}

.text-white {
    color : white
}

.fill-country-unselected {
    fill: #bababa
}

.fill-no-country {
    fill: #f6f6f6
}

g.country.fill-country-unselected:hover {
    opacity: 0.6;
}

g.country {
    --duration: .2s ease-in;
    -webkit-transition: opacity var(--duration);
    transition: opacity var(--duration);
}

g.country:not(.fill-country-unselected):hover {
    filter: brightness(1.1);
}

/** CountriesInfo colors **/
    /* Less than 1280 pixels */
        .bg-es {
            background-color: var(--dark-green)
        }
        .bg-fr {
            background-color: var(--light-green)
        }
        .bg-it {
            background-color: var(--blue)
        }
        .bg-de {
            background-color: var(--purple)
        }
        .bg-at {
            background-color: var(--yellow-green)
        }
        .bg-sk {
            background-color: var(--dark-green)
        }
        .bg-pl {
            background-color: var(--light-green)
        }
        .bg-be {
            background-color: var(--blue)
        }
        .bg-nl {
            background-color: var(--purple)
        }
        .bg-dk {
            background-color: var(--yellow-green)
        }
        .bg-se {
            background-color: var(--dark-green)
        }
        .bg-cz {
            background-color: var(--light-green)
        }
        .bg-pt {
            background-color: var(--blue)
        }
        .bg-lv {
            background-color: var(--purple)
        }
        .bg-fi {
            background-color: var(--yellow-green)
        }
        .bg-hu {
            background-color: var(--dark-green)
        }
        .bg-ie {
            background-color: var(--light-green)
        }
        .bg-si {
            background-color: var(--blue)
        }
        .bg-hr {
            background-color: var(--purple)
        }

        .icon-es {
            color: var(--dark-green)
        }
        .icon-fr {
            color: var(--light-green)
        }
        .icon-it {
            color: var(--blue)
        }
        .icon-de {
            color: var(--purple)
        }
        .icon-at {
            color: var(--yellow-green)
        }
        .icon-sk {
            color: var(--dark-green)
        }
        .icon-pl {
            color: var(--light-green)
        }
        .icon-be {
            color: var(--blue)
        }
        .icon-nl {
            color: var(--purple)
        }
        .icon-dk {
            color: var(--yellow-green)
        }
        .icon-se {
            color: var(--dark-green)
        }
        .icon-cz {
            color: var(--light-green)
        }
        .icon-pt {
            color: var(--blue)
        }
        .icon-lv {
            color: var(--purple)
        }
        .icon-fi {
            color: var(--yellow-green)
        }
        .icon-hu {
            color: var(--dark-green)
        }
        .icon-ie {
            color: var(--light-green)
        }
        .icon-si {
            color: var(--blue)
        }
        .icon-hr {
            color: var(--purple)
        }
    /* END - Less than 1280 pixels */
    /* Greater than 1280 pixels */

        @media (min-width: 1280px) {
            .bg-es {
                background-color: var(--dark-green)
            }
            .bg-fr {
                background-color: var(--light-green)
            }
            .bg-it {
                background-color: var(--blue)
            }
            .bg-de {
                background-color: var(--dark-green)
            }
            .bg-at {
                background-color: var(--purple)
            }
            .bg-sk {
                background-color: var(--blue)
            }
            .bg-pl {
                background-color: var(--yellow-green)
            }
            .bg-be {
                background-color: var(--blue)
            }
            .bg-nl {
                background-color: var(--purple)
            }
            .bg-dk {
                background-color: var(--yellow-green)
            }
            .bg-se {
                background-color: var(--light-green)
            }
            .bg-cz {
                background-color: var(--light-green)
            }
            .bg-pt {
                background-color: var(--yellow-green)
            }
            .bg-lv {
                background-color: var(--dark-green)
            }
            .bg-fi {
                background-color: var(--blue)
            }
            .bg-hu {
                background-color: var(--dark-green)
            }
            .bg-ie {
                background-color: var(--yellow-green)
            }
            .bg-si {
                background-color: var(--yellow-green)
            }
            .bg-hr {
                background-color: var(--light-green)
            }

            .icon-es {
                color: var(--dark-green)
            }
            .icon-fr {
                color: var(--light-green)
            }
            .icon-it {
                color: var(--blue)
            }
            .icon-de {
                color: var(--dark-green)
            }
            .icon-at {
                color: var(--purple)
            }
            .icon-sk {
                color: var(--blue)
            }
            .icon-pl {
                color: var(--yellow-green)
            }
            .icon-be {
                color: var(--blue)
            }
            .icon-nl {
                color: var(--purple)
            }
            .icon-dk {
                color: var(--yellow-green)
            }
            .icon-se {
                color: var(--light-green)
            }
            .icon-cz {
                color: var(--light-green)
            }
            .icon-pt {
                color: var(--yellow-green)
            }
            .icon-lv {
                color: var(--dark-green)
            }
            .icon-fi {
                color: var(--blue)
            }
            .icon-hu {
                color: var(--dark-green)
            }
            .icon-ie {
                color: var(--yellow-green)
            }
            .icon-si {
                color: var(--yellow-green)
            }
            .icon-hr {
                color: var(--light-green)
            }
        }
    /* END - Greater than 1280 pixels */
/** END - CountriesInfo colors **/

/* Made by: Andry Alexis Reyes Cruz */